$color-primary :#0da52b;
$text-primary : #fff;
$text-color:#001935;
$icon-color:#26ae61;
$border-color:#3b3b3b;
$footer-color:#969696;
$list-border:#e0e0e0;
$list-inbut-color:#929292;
$star-color:#f38500;


:root { --color-font: #808291; --color-primary: #3ed2a7; --color-secondary: #ffb09f; --color-link: #a7a9b8; --color-link-hover: #181b31; --color-gradient-start: #4fda91; --color-gradient-stop: #34dbc5; --color-img-holder: #efefef; }

@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@200;300;400;600;700&display=swap');


@mixin shado-1(){
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

@mixin shado-3 {
    box-shadow: 0 2px 6px 0 rgba(218, 218, 253, 0.65), 0 2px 6px 0 rgba(206, 206, 238, 0.54);
}
@mixin shado-4(){
    box-shadow:  0 2px 3px 0 rgba(218, 218, 253, 0.35), 0 0px 3px 0 rgba(206, 206, 238, 0.35);
}

@mixin shado-5(){
    box-shadow: 0 2px 6px 0 rgba(218, 218, 253, 0.65), 0 2px 6px 0 rgba(206, 206, 238, 0.54);
}

@mixin card-1(){
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius:2px;
}

@mixin big-padding(){
    padding: 50px;
    @media screen and (max-width: 1170px){
        padding: 30px 10px;
    }
    
}
