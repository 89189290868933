.userpages{
    padding-top: 195px;
    .user-side{
        .nav{
            @include shado-1();
            margin-bottom: 30px;
            background-color: #fff;
            .nav-link{
                border-bottom: 1px solid #CCC;
                color: #444;
                font-weight: 600;
            }
            .active{
                background-color: rgba(204, 204, 204, 0.23);
            }
        }
    }
    .user-info{
        padding: 15px;
        h4{
            font-weight: 600;
            font-size: 1.2rem;
        }
        ul{
            margin-top: 12px;
            li{
                font-size: .95rem;
                margin-top: 3px;
            }
        }
    }

}

.kijiu{
    padding-left: 16px !important;
}
.payment-table{
    tr{
        th,td{
            font-size: .9rem;
        }
    }
}
.user-card{
    @include shado-3();
}