header{
  background-color: #FFF;
  box-shadow: 0px 4px 25px 0px #00000050;
  position: absolute;
  border-top: 2px solid #fff;
  @include shado-1();
  width: 100%;
  z-index: 999;
  .header-top{
    padding: 10px;
     @media screen and (max-width: 767px){
        padding: 5px;
     }
    .logoo{
      @media screen and (max-width: 767px){
        padding-left: 0px;
      }
      img{
          width: 185px;
          @media screen and (max-width: 767px){
            max-width: 110px;
          }
            @media screen and (max-width: 400px){
              max-width: 110px;
            }
            @media screen and (max-width: 320px){
              max-width: 110px;
            }
      }
      i{
          color: #444;
          float: right;
          margin-left: 30px;
          padding-top: 15px;
          font-size: 1.4rem;
           @media screen and (max-width: 400px){
            padding-top: 8px;
           }
      }
      
    }
    .cont-info{
      ul{
        float: right;
        margin-bottom: 10px;
         li{
          float: left;
           padding: 5px 10px;
          font-size: .85rem;

          i{
            margin-right: 3px;
          }
        }
      }
     
    }
    .logk-box{
      display: flex;
      .icon{
        padding: 10px;
        padding-top: 4px;
        i{
                font-size: 1rem;
                background-color: $color-primary;
                color: #FFF;
                padding: 8px;
                border-radius: 5px;
         }
      }
      .detail{
          p{
              font-size: .75rem;
          }
          h4{
              font-size: .9rem
          }
      }
    }
    .search-col{
      padding-top: 3px;
      .input-group{
        .input-group-append{
          background-color: $color-primary;
          .input-group-text{
            background-color: $color-primary;
            i{
              color: #FFF;
            }
          }
        }
      }
    }
  }

}
.scroll-to-fixed-fixed{
    background-color: #FFF;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}

.container-nav{
  background-color: rgba(78,77,80,1);
  z-index: 9999;
   .menu{

     ul{
        li{
            float: left;
            padding: 20px 20px;
            color: #FFF;
            font-size: .9rem;
            font-weight: 400;
            text-transform: uppercase;
             @media screen and (max-width: 767px){
                float: none;
                
             }
            a{
              color: #FFF;
            }
            &:hover{
              background-color: #fff;
              color: #444;
              a{
                color: #444;
              }
              .submenu{
                display: block;
              }
            }
        }
     }
     @media screen and (max-width: 767px){
      .btn{
          width: 100%;
          margin-bottom: 20px;
          margin-top: 0px;
        }
     }
     .mbtns{
      float: right;
      margin-top: 15px;
      margin-right: 10px;
      @media screen and (max-width:1042px){
        display: none;
      }
     }
   }
   .submenu{
      position: absolute;
      background-color: #FFF;
      width: 100%;
      left: 0px;
      margin-top: 15px;
      padding: 20px;
      display: none;
      @include shado-1();
      z-index: 999;
      .suboption{
        ul{
            li{
              color: #444 !important;
              padding: 10px;
              float: none;
              a{
                 color: #444 !important;
              }
          }
        }
        
      }
      .fordet{
        margin: auto;
        p{
          color: #444;
          padding: 5px;
          text-align: center;
        }
      }
      .logos{
        ul{
          li{
            width: 33.33%;
          }
        }
      }
   }
}
.vbfg1{
  padding-top: 25px;
}

.activemenu{
  display: block !important;
}