
@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@200;300;400;600;700&display=swap');

* {
    margin: 0px;
    padding: 0px;
    list-style: none;

}
img {
    max-width: 100%;
}

a {
    text-decoration: none;
    outline: none;
    color: #444;
}

a:hover {
    color: #444;
}

ul,ol {
    margin-bottom: 0;
    padding-left: 0;
}

a:hover,
a:focus,
input,
textarea {
    text-decoration: none;
    outline: none;
}
.center{
    text-align: center;
}
.left{
    text-align: left;
}
.right{
    text-align: right;
}
.cp {
    cursor: pointer
}
html,body{
    
    color: #444;
    background: #f9f9f9;
}
p {
    margin-bottom: 0px;
    width: 100%;
}
.no-padding {
    padding: 0px;
}

.no-margin {
    margin: 0px;

}
.hid{
    display: none;
}

.top-mar{
    margin-top: 15px;
}
.h-100{
    height: 100%;;
}
::placeholder {
    color: #747f8a !important;
    font-size: 13px;
    opacity: .5 !important;
}
.container-fluid{
    padding: 0px;
}
h1,h2,h3,h4,h5,h6{
  font-family: 'Overpass', sans-serif;
}
strong{
    //font-family: 'Josefin Sans', sans-serif;
    font-family: 'Overpass', sans-serif;
}
body{
    background-color:#f1f1f145 !important;
    //font-family: 'Josefin Sans', sans-serif;
   font-family: 'Overpass', sans-serif;
    color: #6A6A6A;
    overflow-x: hidden;

}
img{
    max-width: 100%;
    
}
.section-title{
	padding: 30px;
	margin: 0px;
	h2{
		width: 100%;
		text-align: center;
        font-size: 1.8rem;
        font-weight: 400;

	}
	p{
		max-width: 850px;
		text-align: center;
		float: none;
		margin: auto;
	}
	span{
		float: right;
		font-style: italic;
	}
}
.inner-title{
	padding: 20px;
	padding-left: 0px;
    margin: 0px;
    margin-bottom: 10px;
    padding-bottom: 0px;
    border-bottom: 1px solid #ccccccc4;
    display: block;
    padding-right: 0px;
	h2{
		width: 100%;
		text-align: center;
		font-size: 1rem;
        font-weight: 600;
        text-align: left;
        border-bottom: 1px solid #863dd9;
        padding-bottom: 10px;
        margin-bottom: 0px;
        width: 300px;
	}
	p{
		width: 100%;
		text-align: center;
    }
    .btn{
        float: right;
        margin-top: -38px;
        font-weight: 600;
        font-size: .8rem;
    }
}
.page-nav{
    padding: 70px;
    text-align: center;
    padding-top: 230px;
    ul{float: none;margin: auto;width: auto;}

     @media screen and (max-width: 576px){
         
         padding: 20px;
         padding-top: 90px;
    }
    @media screen and (max-width: 356px){
        padding: 20px;
        padding-top: 90px;
    }
    h2{
        font-size: 32px;
        width: 100%;
        color: #444;
        font-weight: 600;
        @media screen and (max-width: 600px){
            font-size: 26px;
        }
    }
    ul li{
        float: left;
        margin-right: 10px;
        margin-top: 10px;
        font-size: 16px;
        font-size: .95rem;
        i{
            width: 40px;
            text-align: center;
            color: #444;

        }
        a{
            color: #444;
        }
    }
}
.btn-success{
	background-color: #26ae61;
	border-color: #26ae61;
	&:hover{
		background-color: #26ae61 !important;
		border-color: #223d50 !important;
	}
	&:active{
		background-color: #26ae61 !important;
		border-color: #26ae61 !important;
	}
	&:focus{
		background-color: #26ae61 !important;
		border-color: #26ae61 !important;
		box-shadow: none !important;
	}
}
.btn-primary{
	background-color: $color-primary;
	border-color: $color-primary;
	&:hover{
		background-color: $color-primary !important;
		border-color: $color-primary !important;
	}
	&:active{
		background-color: $color-primary !important;
		border-color: $color-primary !important;
	}
	&:focus{
		background-color: $color-primary !important;
		border-color: $color-primary !important;
		box-shadow: none !important;
	}
}
.btn{
	@include card-1();
}
.form-control{
	&:focus{
		box-shadow: none !important;
		border:2px solid  $color-primary;
	}

}
.btn-light{
	background-color: #FFF;
	color: #3F3F3F;
}

.collapse.show {

    display: block !important;

}

.form-control:focus{
	box-shadow: none;
	border: 2px solid $color-primary !important;
}
.form-control{
	background-color: #F8F8F8;
	margin-bottom: 20px;
    border-radius: 0px;
	&:focus{
		background-color: #FFF;
		border-color: #CCC;
	}
}
.container{
    max-width: 1170px;
    padding-left: 15px;
    padding-right: 15px;
    @media screen and (max-width: 575px){
        padding: 10px 15px;
    }
}


.bg-apt-01{
    //background:url(../images/background/nav-bg.jpg);
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
    position: relative;
    padding:125px 0px 50px 0px;
    text-align:center;
    z-index:99;
    background-color: #cccccc42;


    h3{
        font-size:20px;
        color:$text-color;
    }

    ol{
        li{
            display:inline-block;
            color:$color-primary;
            font-size:16px;
            padding:10px 0px 0px;

            i{
                margin:0px 10px;
            }
        }
    }
}
.light-bg{
    background-color: #FFF; 
}

.num {
  font-family: serif;
}

@media screen and (max-width: 575px){
    .rpxs{
        padding: 0px;
    }
}


/* ===================================== Validation CSS ================================== */

.smart-valid{
    position: absolute;
    width: 250px;
    background-color: #fd3550;
    left: 105%;
    color: #FFF;
    font-size: 16px;
    padding: 8px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    margin-top: 9px;
    padding-left: 18px;
    display: none;
    top:-13px;
    z-index: 999;


}
@media screen and (max-width: 720px) {
    .smart-valid{
        position: absolute;
        width: 250px;
        background-color: #fd3550;
        left: 70px !important;
        color: #FFF;
        font-size: 13px;
        padding: 5px;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        border-radius: 2px;
        margin-top: 9px;
        padding-left: 38px !important;
        display: none;
        top:-54px !important;
        z-index: 999;
    }
    .smart-valid::before{
        box-sizing: border-box;
        left: 5px !important;
        background-color: #fd3550;
        content: "";
        position: absolute;
        z-index: 100;
        height: 15px;
        top: 12px !important;
        transform: rotate(135deg);
        width: 15px;
    }
}
.smart-valid::before{
    box-sizing: border-box;
    left: -12px;
    background-color: #fd3550;
    content: "";
    position: absolute;
    z-index: 100;
    height: 25px;
    top: 8px;
    transform: rotate(135deg);
    width: 25px;

}
.in-err{
    border:2px solid  #fd3550 !important;
}
.smart-val-left{
    right: 100%;
    left: unset;
    top: auto;
    margin-top: -57px;
    margin-right: -35px;
}

.smart-val-left::before{
    left: unset;
    right: -12px;
}

.login-err{
  background-color: #c82333;
  color: #FFF;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  font-size: 0.9rem;
}
.note-icon-caret:before{
    display: none;
}
.req{
    color: #c82333;
}
.body-content{
    padding-top: 30px;
}