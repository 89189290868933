/* =======================================================================
Template Name: Sengan Aolutions
Author:  SmartEye Technologies
Author URI: www.smarteyeapps.com
Version: 1.0
coder name:Prabin Raja
Description: This Template is created to develop classified web applications.
======================================================================= */
// Bootstrap
@import '~bootstrap/scss/bootstrap';

/* ===================================== variable SCSS ================================== */
@import 'variable';
/* ===================================== Basic CSS ==================================== */
@import 'base/basic_setup';
/* ===================================== Buttons CSS ==================================== */
@import 'base/buttons';


/* ===================================== Header CSS ================================== */
@import  'components/header';
/* ===================================== Slider CSS ================================== */
@import  'components/slider';
/* ===================================== Slider CSS ================================== */
@import  'components/product';
/* ===================================== Slider CSS ================================== */
@import  'components/footer';
/* ===================================== Slider CSS ================================== */
@import  'components/user';