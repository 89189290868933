.btn-success{
	background-color: $color-primary;
	border-color: $color-primary;
	&:hover{
		background-color: $color-primary !important;
		border-color: $color-primary !important;
	}
	&:active{
		background-color: $color-primary !important;
		border-color: $color-primary !important;
	}
	&:focus{
		background-color: $color-primary !important;
		border-color: $color-primary !important;
		box-shadow: none !important;
	}
}
