.slider-cover{
	padding-top: 135px;
	@media screen and (max-width: 767px){
		padding-top: 75px;
	}
}

.type-container{
	background: linear-gradient(to bottom, #ffffff 0%,#b5b5b5 99%);
	padding: 30px;
	.typcol{
		padding: 30px;
		img{

		}
	}
}

.opt-title{
	background: url('../images/heading-img.png') repeat-x scroll 0 33px transparent;
	margin-bottom: 1px;
	text-align: center;
	h2{
		background-color: #FFF;
	    width: auto;
	    width: 400px;
	    margin: auto;
	    padding-top: 22px;
	    text-transform: uppercase;
	    font-size: 1.5rem;
	    @media screen and (max-width: 767px){
	    	width: auto;
	    }
	}
}
.our-products{
	background-color: #FFF;
	@include big-padding();
}