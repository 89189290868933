/*----------------------------------------*/
/*  2.9 Footer
/*----------------------------------------*/
.footer {
  background: #f7f7f7;
  border-top: 1px solid #e1e1e1;
  background-image: url('../images/dotted-worldmap.png');
}
.footer-static-top-3 {
  background: #fff;
  border-bottom: 1px solid #e1e1e1;
}
.footer-shipping {
  border-bottom: 1px dashed #e1e1e1;
}
.footer-static-top-3 .footer-shipping {
  border-bottom: none;
}
/*Li's Sipping Inner Box*/
.li-shipping-inner-box {
  text-align: center;
  z-index: 99;
  @media screen and (max-width: 767px){
    margin-bottom: 30px;
  }
}
.footer-static-top-3 .li-shipping-inner-box {
  border-right: 1px solid #e1e1e1;
  padding: 50px 10px 45px;
}
.footer-static-top-3 .li-shipping-inner-box.last-child {
  border-right: none;
}
.shipping-icon {
  display: inline-block;
  width: 67px;
  height: 57px;
}
.shipping-text {
  padding-top: 15px;
}
.shipping-text h2 {
  font-size: 16px;
  font-weight: 500;
  color: #242424;
  padding-bottom: 10px;
}
.shipping-text p {
  margin: 0;
  color: #313131;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
/*Footer Middle*/

.footer-static-middle .footer-logo p.info {
  margin: 20px 0 25px;
  font-size: 14px;
  line-height: 24px;
  color: #888;

}
.footer-static-middle .des > li {
  color: #888;
  font-size: 14px;
  margin: 0;
  font-weight: 400;
  line-height: 24px;
  
}
.footer-static-middle ul.des > li span {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}
/*Footer Block*/
.footer-block > h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #242424;
  margin: 0 0 15px 0;
  cursor: pointer;
  padding-top: 20px;
  text-transform: capitalize;
}
.footer-block > ul > li {
  display: block;
  margin: 0;
  line-height: 32px;
  border: 0;
  padding: 0;
}
.footer-block > ul > li > a {
  font-size: 14px;
  color: #313131;
  line-height: 36px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.footer-block > ul > li > a:hover {
  color: #242424;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}
/*Social Link*/
.social-link {
  line-height: 30px;
  float: right;
  padding-top: 12px;
}
.social-link > li {
  display: inline-block !important;
  transition: all 0.3s ease-in-out;
  float: left;
  margin-right: 15px !important;
  width: 40px;
  text-align: center;
}
.social-link > li > a {
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  text-align: center;
}
.social-link > li > a > i {
  font-size: 18px;
  color: #fff;
}
.social-link > li:hover > a {
  background: #fed700;
}
/*Social Link Color Variations*/
.twitter {
  background: #40c1df;
}
.rss {
  background: #eb8314;
}
.google-plus {
  background: #ed3c32;
}
.facebook {
  background: #3c5b9b;
}
.youtube {
  background: #c72982;
}
.instagram {
  background: #c619b8;
}
/*Footer Newsletter*/
.footer-newsletter {
  float: left;
  width: 100%;
}
.footer-newsletter > h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #242424;
  margin: 25px 0 25px 0;
  cursor: pointer;
  text-transform: capitalize;
}
.footer-newsletter .form-group {
  position: relative;
  float: left;
  width: 100%;
}
.footer-newsletter .form-group input {
  background: transparent;
  border: 1px solid #e1e1e1;
  padding: 0 135px 0 10px;
}
.footer-newsletter .form-group .btn {
  position: absolute;
  right: 0;
  background: #fed700;
  border: none;
  color: #242424;
  height: 45px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  padding: 0 28px;
  display: block;
  line-height: 29px;
  top: 0;
  transition: all 0.5s ease-in-out;
}
.footer-newsletter .form-group .btn:hover {
  background: #333333;
  color: #ffffff;
}
/*Footer Static Bottom*/
.footer-links {
  padding: 0 40px 20px;
}
.footer-links > ul {
  text-align: center;
}
.footer-links > ul > li {
  display: inline-block;
  position: relative;
}
.footer-links > ul > li > a {
  color: #888;
  line-height: 24px;
  letter-spacing: 0.3px;

}
.footer-links > ul > li::after {
  content: "/";
  color: #888;
}
.footer-links > ul > li > a:hover {
  color: #242424;
  text-decoration: underline;
}

.pb-55 {
    padding-bottom: 55px !important;
}

.pt-60 {
    padding-top: 60px !important;
}

.footer-static-middle{
  @include big-padding();
  padding-bottom: 40px;
  border-top: 1px dashed #ccc;


}
.footer-shipping {
  @include big-padding();
}


.fancy {
  line-height: 0.5;
  text-align: center;
}
.fancy span {
  display: inline-block;
  position: relative;  
}
.fancy span:before,
.fancy span:after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 1px solid #a9a9a9;
  border-top: 1px solid #a9a9a9;
  top: 0;
  width: 400px;
}
.fancy span:before {
  right: 100%;
  margin-right: 25px;
   margin-top: 3px;
}
.fancy span:after {
  left: 100%;
  margin-left: 25px;
  margin-top: 3px;
}

.footer-static-bottom{
  background-color: #e6e6e645;
}
.copyright-fg{
  padding-top: 23px !important;
  padding-bottom: 18px !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before{
  background-color: $color-primary;
}
.nav-pag{
  padding-bottom: 10px;
  border-top: 1px solid #CCC;
  padding-top: 10px;
  .pagination{
    float: right;
    margin-bottom: 10px;
  }
}

.page-item:first-child .page-link{
  border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
}

.page-item:last-child .page-link{
  border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
}
.page-link{
  color: $color-primary;
  padding: 0.5rem .85rem;
  font-weight: 500;
}
.page-item.active .page-link{
  background-color:$color-primary;
  border-color: $color-primary;
}
.slick-next{
  right: 10px;
}
.slick-prev {
    left: 10px;
    z-index: 99999;
}
.succmsg{
  padding-top: 50px;
  padding-bottom: 50px;
}