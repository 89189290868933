.our-products {

	.product-row{
		margin-top: 30px;
		.procol{
			text-align: center;
			margin-bottom: 30px;
			.procover{
				padding: 15px;
				padding-bottom: 25px;
				@media screen and (max-width: 767px){
					padding: 3px;
				}
				img{

				}
				.simg{
					display: none;
				}
				.fimg{

				}
			}
			&:hover{
				box-shadow: 0 0 15px 0 #ddd;
				.simg{
					display: inline-block;
				}
				.fimg{
					display: none;
				}
			}
			.proinfo{
				.rating-list{
					margin-bottom: 10px;
					li{
						i{
							color: #444;
							font-size: .9rem;
						}
						.higlit{
							color: #fa8b6e;
						}
					}
				}
				h4{
					font-size: 1rem;
					font-weight: 600;
					line-height: 26px;
					margin-bottom: 20px;
				}
				.price-box{
					margin-bottom: 20px;
					.price{
						
						color: rgb(6, 130, 28);
						font-size: .95rem;
					}
					.old-price{
						color: #818181;
						text-decoration: line-through;
					}
					.special-price{
						padding-left: 10px;
					}
				}
				.cartbtn{
					box-shadow: none;
					border: 1px solid #444;
					border-radius: 5px;
					&:hover{
						background-color: #444;
						color: #fff;
					}
				}
			}
		}
	}
}

.brand-col{
	@include big-padding();
	img{
		padding: 20px;
		@include shado-3();
		background-color: #FFF;
	}
	.item{
		padding-bottom: 20px;
		

	}
	.owl-nav{
		display: none;
	}
	.owl-dots{
		padding-top: 15px;
	}
}

.filters{
	h2{
		font-weight: 700;
		font-size: 1.1rem;
	}
	border-right: 1px solid #cccccc69;
	ul{
		margin-bottom: 30px;
		li{
			font-size: .9rem;
			padding: 3px;
			i{
				margin-right: 5px;
			}
			span{
				float: right;
				font-size: .9rem;
			}
		}
	}
}

.proall{
	margin-left: 0px;
	margin-top: 0px !important;
}



.psingcontainer{
	@include big-padding();
	background-color: #FFF;
	.imagecol{
		text-align: center;
		.xzoom{
			box-shadow: none;
		}
	}
	.product-basic{
		h1{
			font-weight: 600;
			font-size: 2.2rem;
			margin-bottom: 30px;
			@media screen and (max-width: 767px){
				font-size: 1.5rem;
			}
		}
		strong{
			font-size: 1.2rem;
		}
		.btn-row{
			margin-top: 25px;
			margin-bottom: 25px;
		
			.btn{
				width: 40%;
				margin-right: 5%;
				border-radius: 5px;
				padding-top: 13px;
				padding-bottom: 13px;
				font-weight: 600;
				font-size: 1.2rem;
				box-shadow: none;
			}
		}
		p{
			margin-bottom: 10px;
		}
		h5{
			font-weight: 600;
			font-size: 1rem;
			margin-top: 20px;
		}
	}
	.framdet{
		li{
			.nav-link{
				background-color: #999;
				color: #FFF;
				@media screen and (max-width: 767px){
					padding: 5px;
					font-size: .9rem;
				}
			}
			.active{
				background-color: #f1f1f1;
				color: #444;
			}
		}

	}
	.taggvtg{
			background-color: #f1f1f1;
	}
	.bnk{
		text-align: center;
		padding: 20px;
		font-size: .9rem;
		img{
			margin-bottom: 7px;
		}
		p{
			margin-bottom: 0px;
		}
		b{

		}
	}
	.msb{
		padding: 20px;
		font-size: .9rem;
		a{
			color: #0da52b;
		}
	}
	@media screen and (max-width: 767px){
		padding-left: 0px;
	}
}

.product-nfos{
	.infotitl{
		padding: 15px;
		margin-bottom: 10px;
		background-color: #cccccc73;
		text-align: center;
		margin-top: 20px;

		h2{
			font-size: 1.4rem;
			font-weight: 600;
			margin-bottom: 0px;
		}
	}
	.productinforo{
		.scl{
			padding: 10px;
			font-size: .95rem;
			strong{
				font-size: .95rem;
			}
			p{
				margin-bottom: 0px;
				font-size: .9rem;
			}
		}
	}
}
.ppdetails{
	padding-top: 15px;
	p{
		margin-bottom: 15px;
		font-size: .95rem;
	}
	ul{
		font-size: .95rem;
		li{
			padding: 10px;
		}
	}
	img{
		margin-bottom: 10px;
	}
}

.cart-container{
	background-color: #FFF;
	@include big-padding();
	.hear-row{
		border-top: 1px solid #CCC;
		border-bottom: 1px solid #CCC;
		font-weight: 800;
		margin-right: 0px;
		font-size: .95rem;
		.proo{
			padding: 10px;
		}
		.rigth{
			text-align: right !important;
		}
		.center{
			text-align: center;
		}

	}
	.body-row{
		font-size: .95rem;
		border-bottom: 1px solid #CCC;
		margin-right: 0px;
		.proo{
			padding: 10px;
			margin: auto;
		}
		.rigth{
			text-align: right !important;
			
		}
		.dff{
			float: right;
		}
		.center{
			text-align: center;
			
			
		}
		.proimg{
			padding: 0px;
			text-align: center;
			img{
				max-width: 90px;
				@media screen and (max-width: 767px){
					max-width: 100px;
				}
			}
			
		}
		.prodet{
			padding: 0px;
		}
		.proq{
			input{
				max-width: 60px;
			}
		}
	}
	@media screen and (max-width: 767px){
		padding-left: 0px;
		padding-right: 0px;
	}
}
.order-summery{
	padding: 30px 20px;
	background-color: #dedede9e;
	h4{
		font-size: 1rem;
		font-weight: 600;
		border-bottom: 1px solid #8a8a8a;
		padding-bottom: 10px;
		padding-top: 4px;
	}
	ul{
		li{
			padding: 10px;
			padding-left: 0px;
			font-size: .95rem;
			span{
				float: right;
			}
		}
	}
	h5{
		border-top: 1px solid #8a8a8a;
		font-size: 1rem;
		font-weight: 600;
		padding-top: 15px;
		padding-bottom: 5px;
		span{
				float: right;
			}
	}
	.btn{
		width: 100%;
	}
}

.form-row{
	label{
		font-size: .95rem;
		padding-top: 4px;
	}
	.indc{
		float: right;
		padding-top: 4px;
		@media screen and (max-width: 767px){
			float: none;
		}
	}
}

.alca{
	padding-top: 100px;
	text-align: center;
	h4{
		font-weight: 600;
		font-size: 1.3rem;
		margin-bottom: 20px;
	}
	.btn{
		width: 80%;
	}
}


.login-container{
	padding-top: 200px;
	padding-bottom: 100px;

	.login-box{
		background-color: #FFF;
		padding:40px;
		box-shadow: 0 0 15px 0 #ddd;
	}
	.loginju{
		padding-right: 65px;
		border-right: 1px solid #cccccc6b;
		@media screen and (max-width: 767px){
			padding-right: 0px;
			border-right: 0px;
		}
		h4{
			margin-bottom:40px;
			font-weight: 600;
			font-size: 1.2rem;
			text-transform: uppercase;
		}
	}
	@media screen and (max-width: 767px){
		padding: 10px;
	}
}

.caccont{
	margin: auto;
	
	text-align: center;
	h5{
		font-size: 1rem;
	}
	@media screen and (max-width: 767px){
		padding-top: 50px;
		.btn{
			width: 90%;
		}
	}
}

.xzoom-gallery, .xzoom-gallery2, .xzoom-gallery3, .xzoom-gallery4, .xzoom-gallery5{
	@media screen and (max-width: 767px){
		width: 50px;
	}
}

.payment-group{
	li{
		padding: 20px;
		font-size: 1.2rem;
		font-weight: 600;
		i{
			font-size: 2rem;
			margin-left: 10px;
			margin-right: 10px;
			position:absolute;
			color: rgba(62, 139, 56, 1);
		}
		span{
			padding-left: 65px;
			color: rgba(68, 68, 68, 1);
		}
		&:hover{
			@include shado-1();
			background-color: #0da52b;
			color: #FFF !important;
			i{
				color: #FFF !important;
			}
			span{
				color: #FFF !important;
			}
		}
	}
}

.paymebnt-btn{
	float: right;
	text-align: right;
	.btn{
		width: 250px;
		font-weight: 600;
	}
	
}

.all-row{
	text-align: center;
	.btn{
		box-shadow: none;
		border-radius: 5px;
	}
}

.vbiju{
	border: 1px solid #CCC;
	tr{
		td,th{
			border-right:  1px solid #CCC;
		}
	}

}

.table-img{
	h4{
		font-weight: 600;
		margin-top: 15px;
		margin-bottom: 25px;
		font-size: 1.2rem;
	}
}

.paging{
	float: inline-end;
	.pagination{
		
	}
}